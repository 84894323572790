import moment from 'moment'

import isEmpty from 'lodash/isEmpty'

import { refreshToken } from './modules/auth'

const SECONDS_BEFORE_EXPIREY_FOR_REFRESH = 3600 // 1 hour expiry

export const hasValidToken = token => {
  const { issued_at, expires_in = SECONDS_BEFORE_EXPIREY_FOR_REFRESH } = token // eslint-disable-line camelcase
  const now = moment()
  const refreshDate = moment(issued_at).add(+expires_in, 'second') // eslint-disable-line camelcase
  return refreshDate.isAfter(now)
}

export const getValidToken = async () => {
  const { getStore } = await import('../App')
  const store = getStore()
  const state = store && store.getState()
  const token = state && state.auth && state.auth.token

  if (isEmpty(token)) return null
  if (hasValidToken(token)) return token.access_token

  const refreshedToken = getStore().dispatch(refreshToken(token.refresh_token))

  return refreshedToken.access_token
}
