import { handleActions } from 'redux-actions'
import { createPromiseAction } from 'utils/asyncActions'
import * as homepage from './homepage'

export const fetchAds = createPromiseAction('unicorn/homescreen/ads/FETCH_ADS')

const initState = { items: [] }

export default handleActions(
  {
    [fetchAds]: state => ({ ...state, items: [] }),
    [fetchAds.fulfilled]: (state, { payload }) => ({
      ...state,
      items: payload,
    }),
  },
  initState,
)

export const getAds = state => homepage.getAds(state).items
