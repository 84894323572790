export default {
  ENV: process.env.ENV,
  DEBUG: process.env.DEBUG === 'true',
  API_URL: process.env.API_URL,
  WEB_URL: process.env.WEB_URL,
  STATIC_PREFIX: process.env.STATIC_PREFIX,
  GOOGLE_ANALYTICS: process.env.GOOGLE_ANALYTICS,
  CLIENT_ID: process.env.CLIENT_ID,
  CLIENT_SECRET: process.env.CLIENT_SECRET,

  GOOGLE_API: process.env.GOOGLE_API, // Google Map
  FACEBOOK_APP_ID: process.env.FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID, // Google Login

  VENDOR_DASHBOARD: process.env.VENDOR_DASHBOARD,
  REPORT_ERROR_LINK: process.env.REPORT_ERROR_LINK,

  BUGSNAG_KEY: process.env.BUGSNAG_KEY,
  ENABLE_BUGSNAG: process.env.ENABLE_BUGSNAG === 'true',
  ENABLE_NEWRELIC: process.env.ENABLE_NEWRELIC === 'true',
  REDIS_URL: process.env.REDIS_URL,
  REDIS_TTL: Number(process.env.REDIS_TTL),
  CLOUDINARY: process.env.CLOUDINARY,

  ORDER_SUBMIT_VERSION: process.env.ORDER_SUBMIT_VERSION,
  SMART_LOOK: process.env.SMART_LOOK,

  CRYPTO_ALGO: process.env.CRYPTO_ALGO,
  CRYPTO_KEY_HEX_STR: process.env.CRYPTO_KEY_HEX_STR,
  CRYPTO_IV_HEX_STR: process.env.CRYPTO_IV_HEX_STR,

  MULTI_VENDOR_ENABLED: process.env.MULTI_VENDOR_ENABLED,

  FIREBASE_API_KEY: process.env.FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID: process.env.FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID,

  ROKT_ENABLED: process.env.ROKT_ENABLED === 'true',
  ROKT_ACCOUNT_ID: process.env.ROKT_ACCOUNT_ID,
  ROKT_SANDBOX: process.env.ROKT_SANDBOX,

  LUNCH_FRENZY_ENABLED: process.env.LUNCH_FRENZY_ENABLED === 'true',
}
