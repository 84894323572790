import client from 'client'
import getOr from 'lodash/fp/getOr'

const regex = /btq:\/\/venue\/([0-9]+)/ // matches venue deeplink (btq://venue/123)

export const fetchAds = ({ lat, lng, ...params }) =>
  client(true)
    .get('/ads', {
      params,
      headers: {
        'btq-lat': lat,
        'btq-lon': lng,
      },
    })
    .then(getOr([], 'data.ads.slides'))
    .then(ads =>
      ads
        .filter(ad => ad.promotion && ad.action.match(regex))
        .map(ad => ({
          id: Number(ad.action.match(regex)[1]),
          coverImage: ad.source,
          name: ad.promotion.name,
          discount: {
            title: ad.promotion.title,
            time: ad.promotion.subtitle,
          },
        })),
    )
