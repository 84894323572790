import React, { useContext } from 'react'
import get from 'lodash/get'

import { ActionGroup, ActionIcon } from './styled'
import { ConfigContext } from 'contexts/config-context'
import { H3 } from 'components/Text'

const Action = props => {
  const config = useContext(ConfigContext)

  const { icon, label, link, ...rest } = props

  const getMenuLabel = menu => {
    return menu.name === 'free'
      ? get(config, 'menu.refer', 'Refer a friend')
      : menu.label
  }

  return (
    <ActionGroup
      {...rest}
      exact
      activeClassName="active"
      to={link}
      target="_blank"
    >
      <ActionIcon src={icon} />
      <H3 bold color="textDark">
        {getMenuLabel(props)}
      </H3>
    </ActionGroup>
  )
}

export default Action
