export default {
  ABOUT: 'https://info.heyyou.com.au/about',
  WORK_WITH_US: 'https://www.linkedin.com/company/hey-you-app/jobs',
  FAQ: 'https://knowledge.heyyou.com.au',
  PRIVACY: 'https://info.heyyou.com.au/terms-conditions/#privacy',
  TERMS: 'https://info.heyyou.com.au/terms-conditions',
  LIST_BUSINESS: 'https://info.heyyou.com.au/getstarted',
  MERCHANT_HUB: 'https://info.heyyou.com.au/merchant-hub',
  DINE_IN: 'https://info.heyyou.com.au/dine-in',
  INSTAGRAM: 'http://instagram.com/heyyou.app',
  TWITTER: 'https://twitter.com/heyyou_app',
  FACEBOOK: 'http://www.facebook.com/HeyYouApp1',
  APP_DOWNLOAD_IOS: 'https://heyyou.onelink.me/1274865353/download',
  APP_DOWNLOAD_ANDROID: 'https://heyyou.onelink.me/1274865353/9bd063cb',
  BLOG: 'https://info.heyyou.com.au/blog-home',
  GREEN_PROJECTS: 'https://info.heyyou.com.au/project-green',
  MERCHANT_LOGIN: 'https://portal.beattheq.com/login',
  LUNCH_FRENZY:
    'https://info.heyyou.com.au/blog/hy-launches-the-lunch-frenzy-yw2sx-6sksf',
}
